<template>
    <div class="row">
        <div class="col-12">
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="quyền hạn" titleIcon="gear-fill">
                <template slot="actionBefore">
                    <b-tabs class="c-tabs-button mr-2" small pills v-model="filterFormModel.is_active" @input="load">
                        <b-tab title="Tất cả"></b-tab>
                        <b-tab title="Hoạt động"></b-tab>
                        <b-tab title="Tạm khoá"></b-tab>
                    </b-tabs>
                </template>

                <template slot="action">
                    <g-button :loading="saveLoading" size="sm" class="ml-1" @click="create" variant="success" icon="plus" content="Thêm mới" />
                </template>

                <template #cell(name)="{item}">
                    <span :class="item.is_active ? 'c-signal is-success' : 'c-signal'">{{item.name}}</span>
                </template>
                <template #cell(type)="{item}">
                    <span class="badge bg-gray" v-if='item.type && $params.client.typeOptions.find(o => o.value === item.type)'>{{$params.client.typeOptions.find(o => o.value === item.type).text}}</span>
                    <span class="badge bg-warning" v-else>Quyền chung</span>
                </template>
                <template #cell(role)="{item}">
                    <span v-if="params.assignment[item.id]" class="text-success" >{{params.assignment[item.id]}} hành động</span>
                    <span v-else>--</span>
                </template>
                <template #cell(staff)="{item}">
                    <span v-if="params.staffGroup[item.id]">{{params.staffGroup[item.id]}} tài khoản</span>
                    <span v-else>--</span>
                </template>
                <template #cell(action)="{item}">
                    <a class="btn btn-outline-gray pointer btn-sm" :href="`#/setting/roles/${item.id}`">
                        <b-icon icon="link45deg" aria-hidden="true"></b-icon>
                        Phân quyền
                    </a>
                    <g-button :loading="updateLoading" size="sm" variant="outline-info" class="ml-1" icon="pencil" @click='update(item.id)'/>
                    <g-button :loading="delLoading" size="sm" variant="outline-danger" class="ml-1" icon="trash-fill" @click='del(item.id)' />

                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" :title="formTitle" @ok="save" ok-title="Lưu" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus hide-header>
            <form-select label="Phân loại" :required='true' :model="formModel" attribute="type" :errors="formErrors" :options='$params.client.typeOptions'  />
            <form-input label="Tên quyền" :model="formModel" :errors="formErrors" attribute="name" placeholder="Tên quyền" />
            <form-checkbox chk-label="Hoạt động" :model="formModel" attribute="is_active" :errors="formErrors" />
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';

    export default {
        mixins: [list, crud],
        data: function () {
            return {
                service: '/auth-group',
                fields: [
                    {key: 'name', label: "Tên quyền"},
                    {key: 'type', label: "Phân loại", class: 'text-center'},
                    {key: 'role', label: "Quyền hạn"},
                    {key: 'staff', label: "Tài khoản"},
                    {key: 'action', label: "Hành động", class: 'text-center'},
                ],
                defaultFilterFormModel: {
                    is_active: 0
                },
                defaultFormModel: {
                    name: '',
                    type: null,
                    is_active: 1
                }
            }
        },
        methods: {
        },
        mounted() {
            this.$bus.$on('reloadSecurityGroup', this.load);
        },
        beforeDestroy: function () {
            this.$bus.$off('reloadSecurityGroup');
        },
    }
</script>